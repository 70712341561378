import { React, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './TransferForm.css';
import logData from '../api/logData';

const TransferForm = ({ submitted, setSubmitted }) => {
  const [isLoading, setLoading] = useState(false);
  const [firstVal, setFirstVal] = useState('');
  const [secondVal, setSecondVal] = useState('');
  const [thirdVal, setThirdVal] = useState('');
  const [fourthVal, setFourthVal] = useState('');
  const [errorStatus, setErrorStatus] = useState(false);
  const processSubmit = () => {
    setSubmitted(!submitted);
  };

  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
        clearContents();
        processSubmit();
      });
    }
  }, [isLoading]);

  const updateFirst = (e) => {
    setFirstVal(e.target.value.toUpperCase());
  };
  const updateSecond = (e) => {
    setSecondVal(e.target.value.toUpperCase());
  };
  const updateThird = (e) => {
    setThirdVal(e.target.value.toUpperCase());
  };
  const updateFourth = (e) => {
    setFourthVal(e.target.value.toUpperCase());
  };

  const clearContents = () => {
    setFirstVal('');
    setSecondVal('');
    setThirdVal('');
    setFourthVal('');
    setErrorStatus(false);
  };
  const handleSubmitClick = () => {
    if (firstVal.length === 5 && secondVal.length === 5 && thirdVal.length === 5 && fourthVal.length === 5) {
      let data = firstVal + ' ' + secondVal + ' ' + thirdVal + ' ' + fourthVal;
      logData(data);
      setErrorStatus(false);
      setLoading(true);
    } else {
      setErrorStatus(true);
    }
  };
  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 2000));
  }
  return (
    <Container id='form-container' className='h-100' style={{ maxWidth: '500px' }}>
      <Row className='h-100 justify-content-center align-items-center'>
        <Col>
          <Card>
            <Card.Body>
              <h2 id='refugee-header'>Refugee Flight Transfer</h2>
              <hr />
              <Card.Title id='refugee-sub'>FOUR-UNIT TRANSFER</Card.Title>
              <Card.Text>Enter refugee ID codes below:</Card.Text>
              <InputGroup className='mb-4'>
                <FormControl autoCapitalize='off' autoComplete='off' value={firstVal} placeholder='ID 1' disabled={isLoading} maxLength='5' onChange={updateFirst} aria-label='first refugee id code' />
                <FormControl autoCapitalize='off' autoComplete='off' value={secondVal} placeholder='ID 2' disabled={isLoading} maxLength='5' onChange={updateSecond} aria-label='second refugee id code' />
                <FormControl autoCapitalize='off' autoComplete='off' value={thirdVal} placeholder='ID 3' disabled={isLoading} maxLength='5' onChange={updateThird} aria-label='third refugee id code' />
                <FormControl autoCapitalize='off' autoComplete='off' value={fourthVal} placeholder='ID 4' disabled={isLoading} maxLength='5' onChange={updateFourth} aria-label='fourth refugee id code' />
              </InputGroup>
              {!errorStatus ? <p className='invisible error'>Each ID should be 5 characters long</p> : <p className='error'>Each ID should be 5 characters long</p>}
              <hr className='less-top-margin' />
              <Button variant='secondary' disabled={isLoading} onClick={clearContents}>
                Clear
              </Button>{' '}
              <Button variant='primary' disabled={isLoading} onClick={handleSubmitClick} active>
                {' '}
                {isLoading ? <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> : 'Submit'}
              </Button>{' '}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TransferForm;
