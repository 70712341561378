import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Confirmation.css';

const Confirmation = () => {
  return (
    <Container id='form-container' className='h-100' style={{ maxWidth: '500px' }}>
      <Row className='h-100 justify-content-center align-items-center'>
        <Col>
          <Card>
            <Card.Body>
              <h2 id='refugee-header'>Submission Received</h2>
              <hr />
              <Card.Title>Please wait for confirmation and document delivery.</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Confirmation;
