import { React, useState } from 'react';
import './App.css';
import Confirmation from './Confirmation';
import TransferForm from './TransferForm';

function App() {
  const [submitted, setSubmitted] = useState(false);
  return <div className='App'>{!submitted ? <TransferForm submitted={submitted} setSubmitted={setSubmitted} /> : <Confirmation />}</div>;
}

export default App;
